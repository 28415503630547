<template>
    <div>
        <h1>
            {{
            $store.state.language.layout.bread_crumbs.change_pw
            }}
        </h1>
        <div class="row">
            <div class="col-md-6">
                <label for="pw">
                    {{ $store.state.language.agent.login_password }}
                </label>
                <input type="password" class="form-control" id="pw" v-model="formData.pw" />
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label for="confirm_pw">
                    {{ $store.state.language.agent.confirm_pw }}
                </label>
                <input type="password" class="form-control" id="confirm_pw" v-model="formData.confirm_pw" />
            </div>
        </div>
        <div class="row">
            <div class="col-12 my-4">
                <button class="btn btn-primary btn-lg" @click="send_form()">
                    <i class="fa fa-upload"></i>&nbsp;
                    {{ $store.state.language.agent.form_submit }}
                </button>
            </div>
        </div>
    </div>
</template><script>
export default {
    data() {
        return {
            formData: {
                pw: "",
                confirm_pw: ""
            }
        };
    },
    methods: {
        send_form() {
            let vue = this,
                msg = "";
            if (vue.formData.pw == "") {
                msg =
                    vue.$store.state.language.agent.login_password +
                    " " +
                    vue.$store.state.language.agent.form_invaild.required;
            } else if (vue.formData.confirm_pw == "") {
                msg =
                    vue.$store.state.language.agent.confirm_pw +
                    " " +
                    vue.$store.state.language.agent.form_invaild.required;
            } else if (vue.formData.pw != vue.formData.confirm_pw) {
                msg =
                    vue.$store.state.language.agent.login_password +
                    " " +
                    vue.$store.state.language.agent.form_invaild.do_not_match;
            }
            if (msg == "") {
                vue.$swal.fire({
                    html: `
                    <div style="margin:20px">
                        <i class="fa fa-spin fa-sync fa-4x"></i>
                    </div>
                    `,
                    showConfirmButton: false,
                    allowOutsideClick: false
                });
                let form = {};
                for (let f in vue.formData) {
                    if (f == "files") {
                        form[f] = JSON.stringify(vue.formData[f]);
                    } else {
                        form[f] = vue.formData[f];
                    }
                }
                vue.$store
                    .dispatch("post_form", {
                        payload: {
                            url: "/api/index.php?act=change_pw",
                            data: form
                        }
                    })
                    .then(res => {
                        if (res.data.status == "ok") {
                            vue.$swal
                                .fire({
                                    icon: "success",
                                    timer: 1500
                                })
                                .then(() => {
                                    vue.$router.push("/agent/login");
                                });
                        } else {
                            vue.$swal.fire({
                                icon: "error",
                                title: "Error!"
                            });
                        }
                    });
            } else {
                vue.$swal.fire({
                    icon: "error",
                    title: msg
                });
            }
        }
    }
};
</script>
